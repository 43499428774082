"use strict";

import { ready, wrapElement, bindGlobalResizeMessage } from "@wearegood/good-utilities";

// import ShowhideAPI from "Modules/Showhide";
// import ModalAPI from "Modules/Modal";
import SmartImageAPI from "Modules/SmartImage";
import InlineVideoAPI from "Modules/InlineVideo";
import NavigationAPI from "Modules/Navigation";
// import TabsAPI from "Modules/Tabs";
// import CarouselAPI from "Modules/Carousel";

/**
 * initialiseComponentModules - call module init functions
 *
 * @returns {type} Description
 */ 
function initialiseComponentModules() {
  NavigationAPI.initialiseNavigation();
  SmartImageAPI.initSmartImages(); 
  InlineVideoAPI.initInlineVideos(); 
  // ModalAPI.initialiseModals();
  // TabsAPI.initialiseTabs(); 
  // ShowhideAPI.initialiseShowhide();
  // CarouselAPI.initialiseCarousels();

  bindGlobalResizeMessage();
}

ready(initialiseComponentModules);